.container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .input {
        border: 1px solid #d3d5da;
        border-radius: 4px;
        padding: 4px 8px;
        font-weight: 400;
        color: #1c293e;
        width: 100%;

        &.sm {
            height: 24px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.024px;
        }

        &.md {
            height: 28px;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.026px;
            max-width: 632px;
        }

        &.error {
            outline: 1px solid #ef4433;
            border: 1px solid transparent;
        }

        &.read {
            &:read-only {
                border: none;
                outline: none;
                padding: 4px 0;
                cursor: default;

                &:focus {
                    outline: none;
                    border: none;
                }
            }
        }

        &:focus {
            outline: 2px solid #7244fb;
            border: 1px solid transparent;
        }
        &:disabled {
            border: 1px solid #eceef0;
            background-color: #fff;
            color: #b9bdc3;
        }
        &::placeholder {
            color: #9ea4ac;
        }
    }

    .error_message {
        color: #d93e2e;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.024px;
    }
}
