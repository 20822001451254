.accountDeleteModalContent {
    .question {
        margin-bottom: 4px;
        color: var(--color-text-primary, var(--netural-l-800, #1c293e));
        /* normal/body/01 */
        font-size: 13px;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.026px;
    }
    .description {
        color: var(--color-text-secondary, var(--netural-l-500, #7a828e));
        /* normal/body/01 */
        font-size: 13px;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.026px;
    }
    .email {
        margin: 8px 0;
        display: inline-block;
        color: var(--color-text-brand-primary, var(--purple-600, #6137df));
        /* normal/body/02 */
        font-size: 13px;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.13px;
    }
}
