.ServiceModelSpecPopup {
  position: absolute;
  margin: 5% 8%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
  width: 70vw;
  //   height: 70vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  color: var(--color-text-primary, #1c293e);
  font-style: normal;

  .title_wrapper {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.064px;
    }

    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
      color: #9ea4ac;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #86888d;
      }
    }
  }

  table {
    border-collapse: collapse;
    border-top: 2px solid #d0bfff;
    border-bottom: 2px solid #d0bfff;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #dedfe2;
    border: 1px solid #eceef0;
    border: 1px solid #bea9fd;
    border: 1px solid #d0bfff;
    border: 1px solid #e1d7ff;

    padding: 8px 4px;
  }

  th {
    background: var(--netural-l-50, #f5f6f7);
    background: var(--purple-25, #f5f3ff);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }
  td {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.024px;
  }

  .no_left_line {
    border-left: none;
  }

  .no_right_line {
    border-right: none;
  }
}
