.EventSidePanel {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    padding-top: 64px;

    display: flex;
    height: 100%;
    width: 568px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;

    border-left: 1px solid var(--color-border-01, #dedfe2);
    background: var(--color-layer-01, #fff);

    /* shadow/sidepanel */
    box-shadow: -1px 0px 12px 0px rgba(0, 0, 0, 0.24);

    color: var(--color-text-primary, var(--netural-l-800, #1c293e));
    font-style: normal;

    right: -100%;
    animation: slideFromRight 0.8s forwards;

    &.slideout {
        right: 0;
        animation: slideToRight 0.8s ease-in-out forwards;
    }

    .header {
        width: 100%;
        height: 68px;
        padding: 24px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: -0.064px;
        background: var(--green-100, #c8f1e2);

        .title_wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .icon {
            display: flex;
            align-items: center;
        }

        .closeIcon {
            display: flex;
            align-items: center;
            cursor: pointer;

            width: 24px;
            height: 24px;
            color: #434d5f;

            &:hover {
                color: #7a828e;
            }
        }

        &.info {
            background: var(--green-100, #c8f1e2);
        }

        &.warning {
            background: var(--yellow-100, #ffefd0);
        }

        &.critical {
            background: var(--orange-100, #ffe4c6);
        }

        &.severe {
            background: var(--red-100, #ffd1cd);
        }
    }

    .content_container {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 8px;
        width: 100%;

        .item_wrapper {
            display: flex;
            gap: 8px;
            height: 28px;

            .label {
                width: 240px;
            }

            .content {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px; /* 153.846% */
                letter-spacing: -0.026px;
            }
        }

        hr {
            width: 100%;
            height: 1px;
            border: 0;
            background: var(--netural-l-150, #dedfe2);
            margin-bottom: 8px;
        }
    }

    @keyframes slideFromRight {
        to {
            right: 0;
        }
    }

    @keyframes slideToRight {
        to {
            right: -100%;
        }
    }
}
