.LeftLabelInput {
    display: flex;
    flex-direction: row;
    width: 880px;
    height: 28px;
    color: #7a828e;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    gap: 8px;
    align-items: flex-start;

    .input_header {
        display: flex;
        width: 240px;
        margin-bottom: 8px;
        padding: 3px 0 3px 0;
        gap: 4px;
    }
    .input_field {
        width: 632px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #d3d5da;
        padding: 8px 12px;
        box-sizing: border-box;
    }

    .input_field:focus {
        outline: none;
        border-color: #d3d5da;
    }

    .input_field::placeholder {
        color: #9ea4ac;
        font-weight: 400;
    }

    .input_field:valid {
        color: #7a828e;
        font-weight: 400;
    }

    img {
        width: 36px;
        height: 36px;
        position: absolute;
    }
}
