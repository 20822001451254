.CheckSelectBox {
    width: 320px;
    height: 28px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid #d3d5da;
    color: #7a828e;
    background-color: #ffffff;
    padding: 0 8px;
    gap: 4px;

    .option {
        color: #7a828e;
    }
}

.CheckSelectBox:focus {
    outline: 1px solid #d3d5da;
}

.optionField:disabled {
    color: #b9bdc3;
}
