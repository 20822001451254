.button {
    width: max-content;
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    font-weight: 400;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.sm {
        padding: 4px 8px;
        height: 24px;
        /* compact/body/02 */
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.12px;

        .icon {
            > :first-child {
                width: 16px;
                height: 16px;
            }
        }
    }

    &.md {
        padding: 4px 8px;
        height: 28px;
        /* normal/body/02 */
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.13px;

        .icon {
            > :first-child {
                width: 18px;
                height: 18px;
            }
        }
    }

    &.lg {
        padding: 6px 16px;
        height: 36px;
        /* large/body/02 */
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.16px;

        .icon {
            > :first-child {
                width: 20px;
                height: 20px;
            }
        }
    }

    &.primary {
        &.filled {
            font-weight: 500;
            background: var(--purple-500, #7244fb);
            color: var(--color-text-inverse, var(--netural-l-0, #fff));

            &:hover {
                background: #6137df;
            }
            &:active {
                background: #5130b2;
            }
            &:disabled {
                background-color: #dedfe2;
                color: #b9bdc3;
                font-weight: 400;
            }
        }

        &.outline {
            border: 1px solid var(--color-border-brand-primary, #7244fb);
            background: var(--color-button-secondary-01, #fff);
            color: #6137df;

            &:hover {
                background: #eceef0;
            }
            &:active {
                background: #dedfe2;
            }
            &:disabled {
                border: 1px solid var(--color-border-disable, #eceef0);
                background: var(--color-button-secondary-01, #fff);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
                .icon {
                    color: inherit;
                }
            }
            .icon {
                color: #7244fb;
            }
        }

        &.ghost {
            color: #6137df;

            &:hover:not(:disabled) {
                background: #eceef0;
            }
            &:active:not(:disabled) {
                background: #dedfe2;
            }
            &:disabled {
                color: #d3d5da;
                .icon {
                    color: inherit;
                }
            }
            .icon {
                color: #7244fb;
            }
        }
    }

    &.secondary {
        &.filled {
            background: var(--color-button-secondary-02, #eceef0);
            color: var(--color-text-primary, #1c293e);

            &:hover {
                background: var(--color-button-secondary-hover-02, #dedfe2);
            }
            &:active {
                background: var(--color-button-secondary-active-02, #d3d5da);
            }
            &:disabled {
                background: var(--color-button-disable, #f5f6f7);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
            }
        }

        &.outline {
            border: 1px solid var(--color-border-02, #d3d5da);
            background: var(--color-button-secondary-01, #fff);
            color: var(--color-text-primary, #1c293e);

            &:hover {
                background: var(--color-button-secondary-hover-01, #eceef0);
            }
            &:active {
                background: #dedfe2;
            }
            &:disabled {
                border: 1px solid var(--color-border-disable, #eceef0);
                background: var(--color-button-secondary-01, #fff);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
                .icon {
                    color: inherit;
                }
            }
        }

        &.ghost {
            color: #1c293e;

            &:hover:not(:disabled) {
                background: #eceef0;
            }
            &:active:not(:disabled) {
                background: #dedfe2;
            }
            &:disabled {
                color: #d3d5da;
                .icon {
                    color: inherit;
                }
            }
        }

        .icon {
            color: #434d5f;
        }
    }

    &.delete {
        &.filled {
            background: var(--red-500, #ef4433);
            color: #ffffff;
            &:hover {
                background-color: #d93e2e;
            }
            &:active {
                background-color: #b82719;
            }
            &:disabled {
                background: var(--color-button-disable, #f5f6f7);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
            }
        }
        &.outline {
            border: 1px solid var(--color-border-error, #ef4433);
            background: var(--color-button-secondary-01, #fff);
            color: #ef4433;
            &:hover {
                background-color: #fdeceb;
            }
            &:active {
                background-color: #ffd1cd;
            }
            &:disabled {
                border: 1px solid var(--color-border-disable, #eceef0);
                background: var(--color-button-secondary-01, #fff);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
                .icon {
                    color: inherit;
                }
            }
        }
        .icon {
            color: #ef4433;
        }
    }

    &:disabled {
        cursor: default;
    }
}
