.RefreshButton {
  display: flex;
  align-items: center;
  color: #d3d5da;
  transform: rotate(360deg);
  transition: transform 0.5s ease-out;

  &:hover {
    color: #b9bdc3;
  }

  &:active {
    color: #9ea4ac;
    transform: rotate(0deg);
    transition: 0s;
  }
}
