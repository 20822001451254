.TopLabelInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .input_header {
        display: flex;
        flex-direction: row;
        color: #7a828e;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.002em;
        text-align: left;
        gap: 4px;
    }

    img {
        width: 36px;
        height: 36px;
        position: absolute;
    }
}
