.SignUp {
    height: inherit;
    height: max-content;
}

.SignUp .page_content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.026px;
    height: inherit;
}

.SignUp .logo_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 0px;
    gap: 16px;
}

.SignUp .logo_text {
    width: calc(100vw - 37px);
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: 0.02em;
    text-align: left;
    background: linear-gradient(
        95deg,
        #ae43e3 3.41%,
        rgba(116, 120, 239, 0.9) 19.81%,
        rgba(61, 110, 236, 0.85) 34.23%,
        rgba(53, 82, 186, 0.8) 47.15%,
        rgba(22, 152, 152, 0.75) 62.03%,
        rgba(117, 208, 72, 0.7) 77.9%,
        rgba(63, 192, 209, 0.65) 94.74%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 37px;
    margin-top: 12px;
}

.SignUp .title_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 44px;
}

.SignUp .field_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 12px;
}

.SignUp .field_wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 8px;
    gap: 24px;
}

.SignUp .warning_text {
    display: inline-flex;
    margin-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
}

.SignUp .TopLabelInput .input_field {
    width: 724px;
}

.SignUp .field_wrapper .TopLabelInput .input_field {
    width: 350px;
}

.SignUp .field_wrapper.country {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.SignUp .page_content {
    padding: 24px 56px;
}

.SignUp .password {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.SignUp .field_checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    padding: 0px 8px 8px 8px;
    color: rgba(0, 0, 0, 0.48);
}
