/* your css code */
.PreAuthenticationLayout {
    width: 100vw;
    height: max-content;
    min-height: 100vh;
    min-width: max-content;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.026px;

    background: linear-gradient(
            222.67deg,
            rgba(229, 207, 255, 0.9) 7.82%,
            rgba(241, 236, 255, 0) 76.02%
        ),
        linear-gradient(
            131.16deg,
            rgba(187, 234, 255, 0.8) 7.36%,
            rgba(241, 236, 255, 0) 73.32%
        ),
        #f1ecff;

    .top_layer {
        position: absolute;
        left: 45.27%;
        right: 29.05%;
        top: 1.38%;
        bottom: 68.84%;

        background: rgba(158, 186, 255, 0.2);
        filter: blur(100px);
        transform: rotate(15.25deg);
    }
}

.PreAuthenticationLayout.one_color {
    background: #f5f6f7;
}

/* your css code */
.PreAuthenticationLayout__outlet {
    display: flex;
    flex-direction: column;
    height: max-content;
}

.page_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.form_wrapper {
    margin-top: 56px;
    justify-content: flex-start;
}

.asterisk {
    display: flex;
    padding-top: 2px;
}

.PreAuthenticationLayout__footer {
    position: fixed;
    bottom: 32px;
}

.PreAuthenticationLayout__footer.relative {
    position: relative;
    bottom: 12px;
}

@media (max-height: 672px) {
    .PreAuthenticationLayout__footer {
        display: none;
    }
}
