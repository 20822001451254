/* your css code */
.DeploymentDetail {
    width: 100%;
    height: auto;
    padding: 16px;
    border-radius: 4px;
    background: var(--netural-l-0, #fff);

    .header {
        display: flex;
        justify-content: space-between;

        h2 {
            margin-bottom: 24px;
            margin-right: 8px;
            max-width: 100%;
            min-width: 240px;
            color: #1c293e;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.06px;
        }
    }

    .ModalContent {
        gap: 4px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 13px;
            font-weight: 550;
            line-height: 20px;
            letter-spacing: -0.002em;
            color: rgba(28, 41, 62, 1);
        }

        p {
            color: rgba(122, 130, 142, 1);
        }

        .deployment_name {
            margin: 10px 0px;
            font-weight: 500;
            letter-spacing: -0.01em;
            text-align: left;
            color: rgba(97, 55, 223, 1);
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .horizontal_wrapper {
            display: flex;
            align-items: flex-start;

            display: grid;
            grid-template-columns: 240px 1fr;
            gap: 8px;

            .label {
                width: 240px;
                height: 28px;
                display: flex;
                align-items: center;
            }

            .status_container {
                display: flex;
                gap: 8px;
            }
        }

        .horizontal_wrapper_gray {
            display: flex;
            align-items: flex-start;
            background: rgba(245, 246, 247, 1);
            display: grid;
            grid-template-columns: 240px 1fr;
            gap: 8px;
            width: fit-content;
            padding: 8px;

            .label {
                width: 240px;
                height: 28px;
                display: flex;
                align-items: center;
            }

            .help {
                color: var(--color-text-helper, var(--netural-l-500, #7a828e));
                /* normal/helper/01 */
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                /* 133.333% */
                letter-spacing: -0.024px;
            }

            input {
                background: rgba(245, 246, 247, 0.75);
            }
        }
    }

    &:last-child .content {
        margin-bottom: 32px;
    }

    hr {
        background: #dedfe2;
        height: 1px;
        border: 0;
    }

    .grid_container {
        margin-left: 240px;
    }
}
