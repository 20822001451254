.container {
    label {
        display: flex;
        flex-direction: row;
        gap: 4px;
        color: var(--color-text-secondary, var(--netural-l-500, #7a828e));
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.002em;

        cursor: pointer;

        .asterisk {
            display: flex;
            padding-top: 2px;
        }
    }
}
