.DeploymentStatusChip {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
    gap: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;

    .title {
        white-space: nowrap;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        > :first-child {
            width: 18px;
            height: 18px;
        }
    }

    // Requested
    &.REQUESTED {
        color: #6137df;
        background-color: #f1ecff;
        .icon {
            color: #7244fb;
        }
    }

    // Acitve
    &.CREATE_COMPLETE {
        color: #0485e3;
        background-color: #e8f5fe;
        .icon {
            color: #1898f5;
        }
    }

    // Create In Progress
    &.CREATE_IN_PROGRESS {
        color: #345ec9;
        background-color: #ecf1fd;
        .icon {
            color: #3d6eec;
        }
    }

    // Deleted
    &.DELETE_IN_PROGRESS,
    &.DELETE_COMPLETE {
        color: #7a828e;
        background-color: #f5f6f7;
        .icon {
            color: #9ea4ac;
        }
    }

    // Error
    &.CREATE_FAILED {
        color: #eaa317;
        background-color: #fff8eb;
        .icon {
            color: #feb72c;
        }
    }
}
