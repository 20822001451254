.FreeNotification {
    display: flex;
    align-items: center;
    gap: 4px;
    height: fit-content;
    padding: 4px 8px;
    border-radius: 14px;
    color: #d93e2e;
    background-color: #fdf6f6;

    .icon {
        color: #f8a9a1;
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
    }

    .description {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.002em;

        strong {
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.01em;
        }

        .sub_description {
            color: #7a828e;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.002em;
            white-space: pre;
        }
    }
}
