.Support {
    width: 100%;
    // min-width: 894px;
    min-width: 920px;
    height: 100vh;
    background-color: #f5f6f7;
    font-style: normal;
    color: var(--color-text-primary, var(--netural-l-800, #1c293e));
    padding: 8px;

    &.complete {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .support_container {
        .card {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: fit-content;
            border-radius: 4px;
            background: var(--netural-l-0, #fff);
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
            padding: 12px 16px;

            .description {
                font-size: 15px;
                font-weight: 500;
                line-height: 24px; /* 160% */
                letter-spacing: -0.06px;
                margin-bottom: 24px;
            }

            .form_container {
                display: flex;
                flex-direction: column;

                width: 912px;
                width: 880px;

                height: auto;

                .content {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .horizontal_wrapper {
                        display: flex;
                        align-items: flex-start;

                        display: grid;
                        grid-template-columns: 240px 1fr;
                        gap: 8px;

                        .label {
                            width: 240px;
                        }

                        .info_text {
                            margin-left: 8px;
                            color: #9ea4ac;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 20px; /* 153.846% */
                            letter-spacing: -0.026px;
                        }

                        textarea {
                            display: flex;
                            width: 632px;
                            height: 180px;
                            align-items: flex-start;
                            flex-shrink: 0;
                            resize: none;
                            border-radius: 4px;
                            border: 1px solid var(--color-border-02, #d3d5da);
                            background: var(--color-field-01, #fff);
                            outline: none;
                            padding: 8px 12px;

                            &:focus {
                                border: 2px solid
                                    var(--color-border-brand-primary, #7244fb);
                            }

                            &.error {
                                outline: 1px solid #ef4433;
                                border: 1px solid transparent;
                            }
                        }

                        textarea::placeholder {
                            color: #9ea4ac;
                            font-weight: 400;
                        }

                        .textarea_wrapper {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;

                            .error_text {
                                color: #d93e2e;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 16px;
                                letter-spacing: -0.024px;
                            }
                        }
                    }
                }
            }
        }

        .commit_bar {
            position: fixed;
            bottom: 0;
            left: 240px;
            padding: 12px 16px;
            display: flex;
            align-items: center;
            width: 100%;
            height: 44px;
            border-top: 1px solid var(--color-border-02, #d3d5da);
            background: var(--netural-l-0, #fff);

            .commit_button {
                display: flex;
                width: 128px;
                padding: 4px 16px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 4px;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px; /* 153.846% */
                letter-spacing: -0.13px;

                color: var(--color-text-inverse, var(--netural-l-0, #fff));
                background: var(--purple-500, #7244fb);

                &:hover {
                    background: var(--purple-600, #6137df);
                }

                &:active {
                    background: var(--purple-700, #5130b2);
                }

                &.disabled {
                    cursor: default;
                    background: var(--color-button-disable, #f5f6f7);
                    color: var(
                        --color-text-disabled,
                        var(--netural-l-300, #b9bdc3)
                    );
                }
            }
        }
    }

    .complete_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 409px;
        height: 296px;

        .text {
            color: var(--color-text-secondary, var(--netural-l-500, #7a828e));
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px; /* 160% */
            letter-spacing: -0.06px;
            text-align: center;
            margin-top: 8px;
            margin-bottom: 28px;
        }
    }
}
