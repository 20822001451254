/* your css code */
.DeploymentCard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px 16px 16px 16px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 2px solid transparent;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);

    &:hover {
        cursor: pointer;
        border: 2px solid var(--Purple-500, #7244fb);
    }

    &.selected {
        border: 2px solid var(--Purple-500, #7244fb);
    }

    .header {
        .title {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.004em;
            text-align: left;
            color: #1c293e;

            .name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .sub_title {
            color: rgba(122, 130, 142, 1);
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
            text-align: left;
        }
    }

    .price {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2px;

        .item {
            padding: 8px;
            gap: 4px;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
            text-align: left;
            color: rgba(122, 130, 142, 1);
            background-color: rgba(245, 246, 247, 1);

            .value {
                display: flex;
                align-items: center;
                gap: 4px;
                color: #1c293e;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.002em;
            }
        }
    }
}
