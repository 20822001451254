.filterChipContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    padding: 2px 8px;
    background-color: #dedfe2;
    color: #1c293e;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9ea4ac;

        > :first-child {
            width: 18px;
            height: 18px;
        }
    }

    .chipText {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.026px;
        white-space: nowrap;
    }

    &:hover {
        background-color: #d3d5da;
    }

    &.selected {
        background-color: #5e6777;
        color: #ffffff;

        &:hover {
            background-color: #7a828e;
        }
    }
}
