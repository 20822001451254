.deploymentBillingCard {
    width: 100%;
    border-radius: 4px;
    background: var(--netural-l-0, #fff);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    width: 912px;
    display: grid;
    grid-template-rows: min-content 0fr;
    transition: grid-template-rows 500ms;

    > :first-child {
        .container {
            padding-top: 12px;
        }
    }

    > :not(:first-child) {
        border-top: 1px solid #dedfe2;
    }

    // Details On/Off Animaition
    &.on {
        grid-template-rows: min-content 1fr;
    }

    .details_wrapper {
        overflow: hidden;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        color: #1c293e;

        .header {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    /* normal/title/03 */
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: -0.06px;
                }
            }

            h3 {
                /* normal/label/02 */
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.026px;
                display: flex;
                gap: 8px;
            }

            p {
                color: #7a828e;
                /* normal/helper/01 */
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: -0.024px;
            }
        }

        .content {
            .amount_container {
                display: flex;
                gap: 8px;

                .vertical_wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .label {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}
