@use 'src/styles/mixins/_shake' as *;

.TestSignIn {
    // width: 100vw;
    // height: 100vh;
    // min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;

    .input_box {
        width: 400px;
        height: 364px;
        border-radius: 8px;
        padding: 48px 32px 40px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 1px 4px 0px #00000033;
        gap: 48px;

        transition: input_container 0.2s ease-in-out;

        &.password {
            gap: 8px;
        }

        .logo_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;
            gap: 16px;
        }

        .id_chip {
            cursor: pointer;
            height: 24px;
            padding: 2px 12px 2px 12px;
            border-radius: 16px;
            border: 1px;
            gap: 8px;
            border: 1px solid #dedfe2;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
            text-align: left;
            color: #1c293e;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .input_container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            &.password {
                gap: 52px;
            }

            .input_wrapper {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }

            .warning_text {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: -0.002em;
                text-align: left;
                color: #d93e2e;
            }

            .warn_button {
                cursor: pointer;
                text-decoration: underline;
            }
            .button_container {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .checkbox_container {
                display: flex;
                align-items: center;
                gap: 8px;
                input {
                    width: 15px;
                    height: 15px;
                    top: 1.5px;
                    left: 1.5px;
                    cursor: pointer;
                }

                label {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.002em;
                    text-align: left;
                    cursor: pointer;
                }
            }

            .button {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.002em;
                text-align: left;
                color: #7a828e;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &.password {
                    align-self: flex-end;
                }

                &:hover {
                    color: #5e6777;
                }
            }
        }
    }

    .shake {
        @include shake;
    }
}
