.ForgotPassword {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-style: normal;
    padding: 40px;

    .content_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
        gap: 24px;

        .box {
            width: 400px;
            padding: 32px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            background: #ffffff;
            box-shadow: 0px 1px 4px 0px #00000033;
        }
    }

    .field_wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        transition: all 0.2s ease-in-out;
    }

    .warning_text {
        color: #f2695c;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.024px;
    }

    #resend_area {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-bottom: 72px;
    }

    .bottom_text {
        color: var(--netural-l-500, #7a828e);
        font-weight: 400;
    }

    #resend_code {
        color: var(--purple-600, #6137df);
        font-weight: 400;
    }
}
