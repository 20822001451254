.text {
    font-size: 12px;
    font-weight: 400;
    vertical-align: middle;
    letter-spacing: -0.002em;
    flex: 1;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.selected {
        color: #6137df;
    }
}
.selectListContainer {
    width: 100%;
    max-height: 200px;
    overflow: scroll;
    background-color: #ffffff;
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    top: 34px;
    border-radius: 4px;
    border: 1px solid #d3d5da;
    .selectListItem {
        padding: 4px 8px;
        position: relative;
        z-index: 2;
        gap: 4px;
        display: flex;
        align-items: center;
        .iconBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
        }
        .tooltip {
            display: none;
            position: absolute;
            z-index: 3;
            font-size: 12px;
            font-weight: 400;
            vertical-align: middle;
            letter-spacing: -0.002em;
            top: 20px;
            left: 30px;
            padding: 4px 8px;
            white-space: nowrap;
            color: #ffffff;
            background-color: #2a2d37;
            border-radius: 4px;
        }
        &:hover {
            background-color: #f5f6f7;
            .tooltip {
                display: block;
                z-index: 3;
            }
        }
        &:active {
            background-color: #dedfe2;
        }
    }
}

.checkedIcon {
    fill: #1898f5;
}
