.LogoTitleDescriptionForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.004em;
        text-align: center;
        color: #1c293e;
    }

    h3 {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.002em;
        text-align: center;
        color: #7a828e;
        width: 336px;
    }
}
