.button {
    width: 20px;
    height: 20px;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f6f7bf;
    color: #434d5f;
    transition: all 0.2s ease-in-out;

    .icon {
        width: fit-content;
        height: fit-content;
    }

    &:hover {
        background-color: #dedfe2;
    }

    &:active {
        background-color: #d3d5da;
    }

    &.on {
        transform: rotate(-180deg);
    }
}
