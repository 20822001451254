.free_trial {
    border-radius: 10px;
    padding: 2px 8px;
    color: #6137df;
    background-color: #f1ecff;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.024px;
}
