@use 'src/styles/mixins/_shake' as *;

.CreateDeployment {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    gap: 16px;
    padding-bottom: 44px;

    .help {
        margin-left: 252px;
        .deployment-name-valication {
            width: 100%;
            height: 16px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #7a828e;
            margin-top: -12px;
        }
        .duplicate-name-text {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #d93e2e;
        }
    }

    .text_container {
        display: flex;
        flex-direction: row;
        width: 240px;
        height: 28px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #7a828e;
        margin-bottom: 8px;
        padding: 3px 3px;
        gap: 4px;
        .service-text {
            white-space: nowrap;
        }
    }

    .ServiceModel {
        width: 1632px;
        height: auto;
        gap: 8px;

        .service-model_container {
            display: flex;
            gap: 8px;

            .service-popup-text {
                color: #7a828e;
                font-size: 13px;
                line-height: 20px;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .card_container {
            display: flex;
            width: 1632px;
            height: auto;
            gap: 16px;
        }
    }

    .selectbox_container {
        display: flex;
        flex-direction: row;
        width: 568px;
        height: 28px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #7a828e;
        gap: 8px;
    }

    .button_container {
        display: flex;
        gap: 8px;
    }
}

.loading_screen {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.shake {
    @include shake;
}
