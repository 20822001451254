.changePasswordModalContent {
    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 4px;

        .vertical_wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-top: 4px;
        }

        .help {
            display: flex;
            flex-direction: column;
            gap: 4px;
            color: var(--color-text-helper, var(--netural-l-500, #7a828e));

            /* normal/helper/01 */
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: -0.024px;

            .complete {
                color: #2cad7e;
            }
        }
    }
}
