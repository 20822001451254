.ag-theme-dui {
  /* Borders */
  --ag-borders: none;
  --ag-input-focus-border-color: transparent;

  /* Headers */
  --ag-header-height: 40px;
  --ag-header-foreground-color: #7a828e;

  /* Rows */
  --ag-row-height: 32px;
  --ag-grid-size: 6px;
  --ag-cell-horizontal-padding: 8px;
  --ag-font-size: 13px;
  --ag-data-color: #1c293e;
  --ag-row-border-color: #d3d5da;

  /* Cells */
  --ag-range-selection-border-color: transparent;

  /* Effect */
  --ag-row-hover-color: #f5f6f7;
  --ag-selected-row-background-color: #e8f5fe;
  --ag-icon-font-family: agGridBalham;

  /* Checkbox */
  --ag-checkbox-checked-color: #1898f5;
  --ag-checkbox-unchecked-color: #9ea4ac;
  --ag-checkbox-indeterminate-color: #1898f5;
}

.ag-theme-dui .ag-header {
  /* normal/label/02 */
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.026px;
  border-bottom: solid 2px #d3d5da;
}
