/* your css code */
.SignUpResendConfirmationPage {
    width: 100%;
    .card {
        width: 448px;
        border-radius: 8px;
        padding: 56px;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);

        .page_content {
            display: flex;
            flex-direction: column;
            align-items: center;

            > img {
                height: 80px;
                width: 80px;
                margin-bottom: 24px;
            }

            .title {
                color: #1c293e;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.004em;
                text-align: center;
                white-space: pre-wrap;
                margin-bottom: 16px;
            }

            .description {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #7a828e;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.002em;
                margin-bottom: 24px;

                strong {
                    color: #6137df;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.01em;
                }
            }
        }
    }
}
